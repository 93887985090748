import React, { Component } from 'react';
import styled from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Copy } from '../style/typography';
import Button from '../components/button';

//NPM
import { FaPlus, FaRegCreditCard, FaQrcode, FaWrench } from 'react-icons/fa';
import { MdPersonAdd, MdFeedback } from 'react-icons/md';
import Fade from 'react-reveal/Fade'

const HeadSection = styled.div`
    text-align: center;
    padding: 50px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
`
const HowSection = styled.div`
  background-image: linear-gradient(to bottom right, #fc444e, #fc7244);
  padding: 40px 40px;
  color: white;
  /*spans the full width*/
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Content = styled.div`
  max-width: 1000px;
`;
const Phone = styled.img`
    max-width: 350px;
    min-height: 340px;
`
const Title = styled.h1``
const SectionTitle = styled.h2`
font-size: 30px;
color: #FFEC40;
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-gap: 20px;

  @media (max-width: 750px) {
    grid-template-columns: 100%;

  }
`;
const Section = styled.div`
  
`;
const SectionHeading = styled.h3`
font-size: 22px;
font-weight: 600;
color: #F6F6F6;
    margin: 10px 0;
`;
const SectionCopy = styled.p`
font-size: 16px;
`
const Highlight = styled.span`
  color: #FFEC40;
  font-weight: 700;
`;

class BetaPage extends Component {
    state = {}
    render() {
        return (
            <Layout>
                <SEO title="Join Our Beta Program" description="Help us make the next release of the WhereDPump!? app our best one yet!" />
                <HeadSection>
                    <Fade bottom distance={'20px'}>
                        <Phone src={require('../images/beta-preview.png')} />

                    </Fade>
                    <Title>Become a Beta Tester</Title>
                    <Copy style={{ maxWidth: '600px' }}>We don't create our best product by sitting in a room with a case of stag and doubles working until 3AM. We create our best product by talking to and listening to our users...to YOU! Request early access to be a part of making the next release of the WhereDPump!? app our best one yet!</Copy>
                    <a target="_blank" href="https://forms.gle/2cHmWtyLMSiXaunf8"><Button title="Request Early Access" /></a>
                </HeadSection>
                <HowSection>
                    <Content>

                        <SectionTitle>How it works</SectionTitle>
                        <Grid>
                            <Section>
                                <MdPersonAdd size={30} />
                                <SectionHeading>Join the program</SectionHeading>
                                <SectionCopy>
                                    Upon acceptance, you will be sent an email with all the instructions for Beta Testing.
            </SectionCopy>

                            </Section>
                            <Section>
                                <FaWrench size={30} />
                                <SectionHeading>Testing</SectionHeading>
                                <SectionCopy>
                                    There are two types of tests:<br />
                                    <Highlight>Coffee Session-</Highlight> interact with the product over a free cup of coffee.<br />
                                    <Highlight>Independent-</Highlight> try to break the app — unsupervised!
            </SectionCopy>
                            </Section>
                            <Section>
                                <MdFeedback size={30} />
                                <SectionHeading>Give Feedback</SectionHeading>
                                <SectionCopy>
                                    Share your experience! We value your feedback and it will be used to improve the product.
            </SectionCopy>

                            </Section>
                        </Grid>

                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <a target="_blank" href="https://forms.gle/2cHmWtyLMSiXaunf8"><Button secondary title="Request Early Access" /></a>
                        </div>

                    </Content>
                </HowSection>
            </Layout>
        );
    }
}

export default BetaPage;