import React, { Component } from 'react';
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'
import './button.css'
import { colors } from '../style/colors';

const Btn = styled.button`
        font-family: "Poppins";
    font-weight: 500;
    padding: 10px 25px;
    border: none;
    font-size: 17px;
    border-radius: 5px;
    cursor: pointer;
    /* text-transform: uppercase; */
    font-family: "Poppins";
    color: ${props => props.secondary ? colors.primary : 'white'};
    background: ${props => props.secondary ? 'white' : colors.primary};
    box-shadow: 0 5px 7px rgba(252, 68, 78, 0.24);
    box-shadow: ${props => props.secondary && 'none'};
    align-self: center;
    transition: 0.3s ease;
    
    :focus {
        outline: none;
    }

    :hover {
    background: ${props => props.secondary && 'rgba(252, 68, 78, 0.8)'};
        color: white;
    }
`
class Button extends Component {
    state = {}
    render() {
        const { link } = this.props
        if (link) {
            return (
                <Link to={this.props.link}><Btn secondary={this.props.secondary}>{this.props.title}</Btn></Link>
            );
        }

        return (
            <Btn secondary={this.props.secondary}>{this.props.title}</Btn>
        );

    }
}

export default Button;

Button.defaultProps = {
    lang: `en`,
    meta: [],
    keywords: [],
}

Button.propTypes = {
    title: PropTypes.string.isRequired,
    secondary: PropTypes.bool,
    link: PropTypes.string,
    meta: PropTypes.array,
    keywords: PropTypes.arrayOf(PropTypes.string),
}