import styled from 'styled-components'

export const SectionTitle = styled.h1`
    font-size: 22px;
    margin: 5px 0 20px 0;
`

export const Copy = styled.p`
    color: #424242;
    font-size: 1rem;
    line-height: 150%;
`